import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
// import { StylesProvider } from '@mui/styles';
// import { StylesProvider } from '@mui/material/styles';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { language } from '../dictionary';

export const ThemeContext = React.createContext();
// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
});
// eslint-disable-next-line no-unused-vars
const cacheLtr = createCache({
  key: 'muiltr'
});
const ThemeProviderWrapper = function (props) {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };
  const lang = language();
  return lang.direction === 'rtl' ? (
    // <StylesProvider injectFirst>
    <ThemeContext.Provider value={setThemeName}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </CacheProvider>
    </ThemeContext.Provider>
  ) : (
    // </StylesProvider>
    // <StylesProvider injectFirst>
    <ThemeContext.Provider value={setThemeName}>
      <CacheProvider value={cacheLtr}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </CacheProvider>
    </ThemeContext.Provider>
    // </StylesProvider>
  );
};

export default ThemeProviderWrapper;
