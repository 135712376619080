export const ClientConstants = {
  LOGIN_SEND_CODE_SUCCESS: 'LOGIN_SEND_CODE_SUCCESS',
  GET_ALL_BLOGS: 'GET_ALL_BLOGS',
  GET_BLOG: 'GET_BLOG',
  GET_TRIGGER: 'GET_TRIGGER',
  GET_PAGE: 'GET_PAGE',
  GET_PAYMENTURL: 'GET_PAYMENTURL',
  GET_ASSISTANTDATA: 'GET_ASSISTANTDATA',
  GET_ALL_PAYMENTS: 'GET_ALL_PAYMENTS',
  GET_PAYMENTSTATUS: 'GET_PAYMENTSTATUS',
  GET_ALL_WALLETLOGS: 'GET_ALL_WALLETLOGS',
  GET_ALL_REVENUELOGS: 'GET_ALL_REVENUELOGS',
  GET_USERPROFILE: 'GET_USERPROFILE',
  GET_ALL_FOLLOWERS: 'GET_ALL_FOLLOWERS',
  GET_ALL_FOLLOWINGS: 'GET_ALL_FOLLOWINGS',
  GET_ALL_TECHNICAL: 'GET_ALL_TECHNICAL',
  GET_VERIFYSECURITYKEY: 'GET_VERIFYSECURITYKEY',
  GET_ALL_MARKETRATES: 'GET_ALL_MARKETRATES',
  GET_ALL_CALENDARS: 'GET_ALL_CALENDARS',
  GET_ALL_FOREXSIGNALS: 'GET_ALL_FOREXSIGNALS',
  GET_ALL_MAINPAGEDATA: 'GET_ALL_MAINPAGEDATA'
};
