import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';

export const en = {
  direction: 'ltr',
  slug: 'en',
  login: 'Register/Login',
  premium: 'Premium membership',
  premiumInfo: 'Tradely premium membership',
  check_and_login: 'Check and login!',
  signOut: 'Sign Out',
  blog: 'Blog',
  charts: 'Charts',
  openAdvancedChart: 'Open advanced chart',
  today: 'Today',

  tomorrow: 'Tomorrow',
  currency: 'Currency',
  date: 'Date',
  time: 'Time',
  event: 'event',
  impact: 'Impact',
  actual: 'Actual',
  forecast: 'Forecast',
  previous: 'Previous',
  lowImpact: 'Low',
  mediumImpact: 'Medium',
  highImpact: 'High',
  notice: 'Notice',
  youAreNotPremiumSignals:
    'You only can see EURUSD signals, because you don`t have a premium access, become a premium member to access all signals.',
  loginPremium: 'Login/Become a Premium Member',

  marketsTitle: (market) =>
    market === 'Forex' ? 'FOREX MARKET' : 'CRYPTOCURRENCY MARKET',
  marketsTitleInfo: (market) =>
    market === 'Forex' ? 'FOREX MARKET...' : 'CRYPTOCURRENCY MARKET...',
  economicCalendar: 'Economic Calendar',
  rates: 'Rates',
  symbol: 'SYMBOL',
  search: 'Search',
  allPairs: 'All pairs',
  welcomeToTradely: 'Welcome to Tradely',
  welcomeToTradelyInfo:
    'Tradely is Your Smart Assistant in Financial Markets...',
  forexMarket: 'Forex Market',
  forexSignals: 'Forex Signals',
  forexSignalsInfo: 'This page will update every minute automatically..',
  howToUseSignals: 'How To Use Signals',
  howToUseSignalsInfo:
    'Pending order should be placed as signal arrives. "Till" time is intended to force exit. Any open trade is "Filled" when "Till" time is about to be reached. Any pending order is "Canceled" when "Till" time is about to be reached. Use trailing-stop to maximize profit.\n' +
    '\n' +
    '\n' +
    '\n' +
    "Please remember that different brokers give different quotes at a specific time. The difference is usually about 5 pips and perhaps more. To overcome this issue we try to average quotes from different brokers and providers' \"average\" results. Nevertheless, it's possible that your trade reaches entry/take-profit/stop-loss level when our trade doesn't and vice versa due to quote difference.",
  cryptoMarket: 'Crypto Market',
  lp: 'LAST',
  chp: 'CHG %',
  ch: 'CHG',
  high: 'HIGH',
  low: 'LOW',
  technicalRating: 'TECHNICAL RATING',

  technicalAnalysis: (symbol) => `${symbol} technical analysis`,
  technicalAnalysisInfo: 'Your technical analysis summary for ',
  minute1: '1 minute',
  minute5: '5 minutes',
  minute15: '15 minutes',
  minute30: '30 minutes',
  hour1: '1 hour',
  hour4: '4 hours',
  day1: '1 day',
  week1: '1 week',
  month1: '1 month',
  editProfile: 'Edit Profile',
  editProfileInfo: 'Here you can change your profile information!',
  aliasIsRequired: 'Alias is required',
  profilePhotoMsg: 'Click on profile photo to change it.',
  alias: 'Alias',
  fullName: 'Full Name',
  telegramId: 'Telegram ID',
  securityKey: 'Security Key',
  first_name: 'First Name',
  last_name: 'Last Name',
  username: 'UserName',
  status: 'Status',
  language: 'Language',
  changeLanguage: 'Change Language',
  notifyBot: 'Notify Bot',
  timeZone: 'TimeZone',
  connectTelegram: 'Connect Telegram',
  telegramDetails: 'Your telegram account details',
  telegramAccount: 'Telegram Account',
  personalDetails: 'Personal Details',
  personalDetailsInfo: 'Click on profile photo to upload a new one!',
  userSettings: 'User Settings',
  userSettingsInfo: 'here you can manage your personal details',
  active: 'Active',
  deactivated: 'Deactivated',
  emailAddress: 'Email Address',
  sendSecurityKey: 'Receive Security Key',
  saveChanges: 'Save Changes',
  save: 'Save',
  cancel: 'Cancel',
  freeMembership: 'Free Membership',
  vipMembership: 'VIP Membership',
  vip: 'VIP',
  profileUpdated: 'Profile details updated.',
  aliasIsDuplicated: 'This alias is taken before, Please choose another!',
  emailIsDuplicated: 'Email is taken before, Please choose another!',
  days: 'days',

  "You're going to need a bigger monitor": 'Multi Chart!',
  'Your  plan has a 1 chart per tab limit. But our upgraded plans let you see up to 8 linked charts , perfectly synced , all in the same window.':
    'Your  plan has a 1 chart per tab limit. But our upgraded plans let you see up to 8 linked charts , perfectly synced , all in the same window.',
  "See what others don't with custom intervals":
    "See what others don't with custom intervals",
  "If you're doing analysis the same way as everyone else":
    "If you're doing analysis the same way as everyone else, how will you find your edge? With custom intervals spot the patterns most traders don't see — available only on our upgraded plans.",
  loginWithTelegram: 'Login with telegram',
  login_description: 'please Scan This QRCode with your mobile phone...',
  goBack: 'Go back',
  loginWithEmail: 'Login with email',

  login_2_description: () => (
    <>
      please Start our Telegram Bot (
      <a href="https://t.me/TradelyProBot" target="_blank" rel="noreferrer">
        @TradelyProBot
      </a>
      ) and get your
      <strong> Telegram ID</strong> and <strong>security key</strong> from
      "Browser Login" section.
    </>
  ),
  emailLoginInfo: 'Please enter your email address to receive a security key.',
  emailLoginSecurityInfo:
    'Please enter the security key that we sent to your email address.',

  Filled: 'Filled',
  Cancelled: 'Cancelled',
  profit: 'Profit: ',
  loss: 'Loss: ',
  pips: 'pips',
  stopLoss: 'Stop Loss: ',
  takeProfit: 'Take Profit: ',
  validTill: 'Valid Till: ',

  oscillators: 'Oscillators',
  movingAverages: 'Moving Averages',
  summary: 'Summary',
  pivots: 'Pivots',
  woodie: 'Woodie',
  classic: 'Classic',
  camarilla: 'Camarilla',
  fibonacci: 'Fibonacci',
  name: 'Name',
  value: 'Value',
  action: 'Action',
  Buy: 'Buy',
  Sell: 'Sell',
  Neutral: 'Neutral',
  'Strong Sell': 'Strong Sell',
  'Strong Buy': 'Strong Buy',
  // New
  close: 'Close',
  paymentInfo: (id) => (
    <>
      <Typography variant="h5">Subscription fee:</Typography>
      <br />
      <Typography>
        ▪️ 1Month : $10
        <br />
        ▪️ 3Month: $27
        <br />
        ▪️ 6Month: $48
        <br />
        ▪️ 1Year: $89
      </Typography>
      <Typography>
        Pay the Subscription fee to this Wallet and contact Support to Activate
        Your Subscription:
        <a href="https://t.me/TradelySupport" target="_blank" rel="noreferrer">
          @TradelySupport
        </a>
        <br />
        Mention your account id when you contact Support.
      </Typography>
      <Typography variant="h5">
        Your Account ID: {id || <small>Login to get your account id</small>}
      </Typography>
    </>
  ),
  payUSDT: 'USDT',
  payUSDT2: 'Pay with USDT',
  payUSDTInfo: () => (
    <>
      <Typography variant="h5">USDT Wallet :</Typography>
      <br />
      <Typography variant="h5">TRC20 :</Typography>
      <Typography color="secondary">
        TGUzedJVa4waGVBwUvszHSYtUPwsqNoaD7
      </Typography>
      <Typography variant="h5">ERC20:</Typography>
      <Typography color="secondary">
        0x1e3b8a522f98fdd2aa79cdf48319abcbd912f79a
      </Typography>
      <Typography variant="h5">BSC:</Typography>
      <Typography color="secondary">
        0x1e3b8a522f98fdd2aa79cdf48319abcbd912f79a
      </Typography>
    </>
  ),
  payTCPay: 'TopChange',
  payTCPay2: 'Pay with TopChange',
  payTCPayInfo: () => (
    <>
      <Typography variant="h5">TopChange Wallet :</Typography>

      <Typography color="secondary">USD1033903</Typography>
    </>
  ),
  payWM: 'WebMoney',
  payWM2: 'Pay with WebMoney',
  payWMInfo: () => (
    <>
      <Typography variant="h5">WebMoney Wallet :</Typography>

      <Typography color="secondary">Z175072480756</Typography>
    </>
  ),
  payPM: 'PerfectMoney',

  payPM2: 'Pay With PerfectMoney',
  payPMInfo: () => (
    <>
      <Typography variant="h5">PerfectMoney Wallet :</Typography>

      <Typography color="secondary">U19315856</Typography>
    </>
  ),
  freePremium: 'Get Free Premium',
  freePremiumInfo: () => (
    <>
      <Typography>
        Contact support to activate your free premium membership if you have an
        account in one of these brokers.
        <br />
        <br />
        If you don't have an account in these brokers, please register with one
        of the following links and deposit to your account the contact support
        to activate your free premium membership.
      </Typography>
      <Box textAlign="center">
        <Button
          href="https://amarketstrading.co/cashback-en/?g=7J5BN"
          target="_blank"
        >
          AMarkets
        </Button>
        <Button
          href="https://alparipartner.com/?partner_id=1260088"
          target="_blank"
        >
          Alpari
        </Button>
        <Button
          href="https://bz.myuserhub.com/?langSelectorCode=en&fromLangSelector=dropdown&pt=40182"
          target="_blank"
        >
          Windsor Brokers
        </Button>
        <Button
          href="https://www.fibogroup.com/?ref=IB_Tradely"
          target="_blank"
        >
          FiboGroup
        </Button>
        <Button href="https://my.roboforex.com/en/?a=csem" target="_blank">
          RoboForex
        </Button>

        <Button
          href="https://www.litefinance.com/?uid=110655160"
          target="_blank"
        >
          Lite Forex(LiteFinance)
        </Button>
        <Button
          href="https://myaccount.opofinance.com/links/go/1073"
          target="_blank"
        >
          OpoFinance
        </Button>
      </Box>

      <br />
      <Box textAlign="center">
        <Button
          href="https://telegram.me/TradelySupport"
          target="_blank"
          variant="outlined"
          color="success"
        >
          Contact Support
        </Button>
      </Box>
    </>
  ),
  paymentMethods: 'Select a payment method:',
  closeChart: 'Close Chart',
  membersArea: 'MEMBERS AREA',
  dashboard: 'Dashboard',
  contactSupport: 'Contact Support',
  premiumBenefits: () => (
    <List
      dense={false}
      subheader={
        <ListSubheader component="h1" sx={{ fontSize: 25 }}>
          Premium Benefits
        </ListSubheader>
      }
    >
      <ListItem divider>
        <ListItemText
          primary="Forex Signals"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="Forex signals on 9 pairs"
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="Alerts"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="Set up unlimited alerts."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="Multiple Charts"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="Multiple charts in one window (up to 8 charts)."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="Indicators"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="The unlimited number of indicators on your charts."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="Timeframes"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="Custom timeframes on your chart."
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Chart types"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="Renko, Kagi, Line Break, and Point & Figure charts to reduce random noise and help you see trends clearer."
        />
      </ListItem>
    </List>
  ),
  yourAccountStatus: 'Your account status',
  notPremium: 'You are not premium',
  daysOfPremium: 'Days of premium remains',
  // New
  homeTitle: 'Tradely',
  homeKeywords:
    'tradely,cryptocurrency,forex,trading,charts,free signals,forex signals, crypto signals,bitcoin,tradingview,metatrader',

  marketsKeywords:
    'market rates,cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  signalsDescription: 'Forex signals on 9 pairs with high win rate',
  signalsKeywords:
    'EURUSD,GBPUSD,USDCHF,USDJPY,USDCAD,AUDUSD,EURJPY,NZDUSD,GBPCHF,forex,trading,technical analysis,forex signals,tradingview,metatrader',

  premiumKeywords:
    'free premium,vip,cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  technicalKeywords:
    ',cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  chartTitle: (symbol) => `${symbol} Price Chart -Tradely`,
  chartInfo: (symbol) => `${symbol} Advanced Price Chart -Tradely`,
  chartKeyword: (symbol) => `${symbol}, Price Chart,tradingview,metatrader`,
  // new
  telegramLink: 'https://t.me/tradelypro',
  instagramLink: 'https://instagram.com/tradely.plus',
  youtubeLink: 'https://www.youtube.com/channel/UC_hL2MdtkYh-e2f4oAXaxAg',
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  thisMonth: 'This Month',
  lastMonth: 'Last Week',
  signalsResult: 'Signals Result',
  yourIdea: 'Your Ideas',
  publishIdea: 'Publish Idea',
  description: 'Description'
};

// export default function en() {
//   return enConst;
// }
