import { ClientConstants } from '../constants/client-constants';

export function ClientReducers(state = [], action) {
  switch (action.type) {
    case ClientConstants.LOGIN_SEND_CODE_SUCCESS:
      return {
        ...state,
        codeStep: action.payload
      };
    case ClientConstants.GET_ALL_BLOGS:
      return {
        ...state,
        blogs: action.payload
      };
    case ClientConstants.GET_BLOG:
      return {
        ...state,
        blog: action.payload
      };
    case ClientConstants.GET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case ClientConstants.GET_PAYMENTURL:
      return {
        ...state,
        paymentUrl: action.payload
      };
    case ClientConstants.GET_ALL_PAYMENTS:
      return {
        ...state,
        payments: action.payload
      };
    case ClientConstants.GET_PAYMENTSTATUS:
      return {
        ...state,
        paymentStatus: action.payload
      };
    case ClientConstants.GET_ASSISTANTDATA:
      return {
        ...state,
        assistantData: action.payload
      };
    case ClientConstants.GET_TRIGGER:
      return {
        ...state,
        trigger: action.payload
      };
    case ClientConstants.GET_ALL_WALLETLOGS:
      return {
        ...state,
        walletLogs: action.payload
      };
    case ClientConstants.GET_ALL_REVENUELOGS:
      return {
        ...state,
        revenueLogs: action.payload
      };
    case ClientConstants.GET_ALL_FOLLOWERS:
      return {
        ...state,
        followers: action.payload
      };
    case ClientConstants.GET_ALL_FOLLOWINGS:
      return {
        ...state,
        followings: action.payload
      };
    case ClientConstants.GET_USERPROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    case ClientConstants.GET_ALL_TECHNICAL:
      return {
        ...state,
        technical: action.payload
      };
    case ClientConstants.GET_VERIFYSECURITYKEY:
      return {
        ...state,
        verifySecurityKey: action.payload
      };
    case ClientConstants.GET_ALL_MARKETRATES:
      return {
        ...state,
        marketRates: action.payload
      };
    case ClientConstants.GET_ALL_CALENDARS:
      return {
        ...state,
        calendars: action.payload
      };
    case ClientConstants.GET_ALL_FOREXSIGNALS:
      return {
        ...state,
        forexSignals: action.payload
      };
    case ClientConstants.GET_ALL_MAINPAGEDATA:
      return {
        ...state,
        mainPageData: action.payload
      };

    default:
      return state;
  }
}
