import { fa } from './fa';
import { en } from './en';
import { useSelector } from 'react-redux';

const languages = {
  fa,
  en
};

export const language = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = useSelector((store) => store['GlobalReducers'].locale || 'en');
  return languages[locale];
};
