import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';

export const fa = {
  direction: 'rtl',
  slug: 'fa',
  login: 'ورود/عضویت',
  premium: 'اشتراک پریمیوم',
  premiumInfo: 'اشتراک پریمیوم تریدلی',
  check_and_login: 'بررسی و ورود!',
  signOut: 'خروج',
  blog: 'بلاگ',
  charts: 'نمودارها',
  openAdvancedChart: 'نمودار پیشرفته',
  today: 'امروز',

  tomorrow: 'فردا',
  currency: 'ارز',
  date: 'تاریخ',
  time: 'زمان',
  event: 'رویداد',
  impact: 'تاثیر',
  actual: 'نتیجه',
  forecast: 'پیش بینی',
  previous: 'پیشین',
  lowImpact: 'کم',
  mediumImpact: 'متوسط',
  highImpact: 'زیاد',
  notice: 'توجه',
  youAreNotPremiumSignals:
    'شما تنها امکان دیدن سیگنال های EURUSD را دارید، جهت دیدن همه سیگنال ها اشتراک پریمیوم خود را فعال کنید.',
  loginPremium: 'ورود/عضو پریویوم شوید',

  marketsTitle: (market) =>
    market === 'Forex' ? 'بازار فارکس' : 'بازار رمز ارز ها',
  marketsTitleInfo: (market) =>
    market === 'Forex' ? 'بازار فارکس' : 'بازار رمز ارز ها...',
  economicCalendar: 'تقویم اقتصادی',
  rates: 'نرخ ها',
  symbol: 'نماد',
  search: 'جستجو',

  allPairs: 'همه نمادها',
  welcomeToTradely: 'به تریدلی خوش آمدید',
  welcomeToTradelyInfo: 'تریدلی، دستیار هوشمند بازارهای مالی...',
  forexMarket: 'بازار فارکس',
  forexSignals: 'سیگنال فارکس',
  forexSignalsInfo: 'این صفحه بصورت خودکار بروزرسانی می شود...',
  howToUseSignals: 'چطور از سیگنال عا استفاده کنم',
  howToUseSignalsInfo:
    'توجه داشته باشید که سیگنال ها بصورت پندینگ اوردر (Pending Order)   هستند.\n' +
    'بلافاصله بعد از دیدن سیگنال پندینگ اوردر خود را بر روی قیمت مشخص شده فعال کنید.\n' +
    'زمان مشخص شده در هر سیگنال برای خروج از معامله در صورت نرسیدن معامله یه حد سود یا ضرر است.\n' +
    'در صورتی که به زمان مشخص شده در زیر هر سیگنال رسیدید معامله را چه در سود چه در ضرر ببندید.\n' +
    'اگر به زمان مشخص شده زیر هر سیگنال رسیدید و هنوز پندینگ اوردر شما فعال نشده بود آن را حذف کنید.\n' +
    'بطور کل زمان مشخص شده در زیر هر سیگنال تاریخ انقضای اعتبار سیگنال است.\n' +
    'برای گرفتن سود بیشتر از سیگنال ها میتوانید از تریلینگ استاپ (trailing-stop) استفاده کنید.\n' +
    '\n' +
    'لطفا توجه داشته باشید که بروکر های مختلف در زمان یکسان قیمت های متفاوتی را نشان میدهند.\n' +
    'این تفاوت ممکن است  گاها تا 5 پیپ یا بیشتر باشد.\n' +
    'برای رفع این مشکل ما سعی میکنیم قیمتی میانگین از چندین بروکر را  در سیگنال ها اعلام کنیم.\n' +
    'به همین دلیل ممکن است گاها پوزیشن (معامله) شما به نقطه ورود / حد سود / حد ضرر رسیده باشد در حالی که برای ما هنوز این اتفاق نیافتاده باشد، و بالعکس\n',
  cryptoMarket: 'بازار رمز ارز',
  lp: 'آخرین',
  chp: 'تغییر %',
  ch: 'تغییر',
  high: 'بیشترین',
  low: 'کمترین',
  technicalRating: 'وضعیت تکنیکال',

  technicalAnalysis: (symbol) => `بررسی تکنیکال ${symbol} `,
  technicalAnalysisInfo: 'جمع بندی بررسی تکنیکال برای  ',
  minute1: '1 دقیقه',
  minute5: '5 دقیقه',
  minute15: '15 دقیقه',
  minute30: '30 دقیقه',
  hour1: '1 ساعت',
  hour4: '4 ساعت',
  day1: '1 روز',
  week1: '1 هفته',
  month1: '1 ماه',
  editProfile: 'ویرایش پروفایل',
  editProfileInfo:
    'شما در این قسمت میتوانید اطلاعات حساب کاربریتان را ویرایش کنید',
  aliasIsRequired: 'نام مستعار الزامیست',
  profilePhotoMsg: 'برای تغییر عکس پروفایل روی آن کلیک کنید.',
  alias: 'نام مستعار',
  fullName: 'نام و نام خانوادگی',
  telegramId: 'شناسه تلگرام',
  securityKey: 'کد امنیتی',
  first_name: 'نام',
  last_name: 'نام خانوادگی',
  username: 'نام کاربری',
  status: 'وضعیت',
  language: 'زبان',
  changeLanguage: 'تغییر زبان',
  notifyBot: 'ربات اطلاع رسان',
  timeZone: 'منطقه زمانی',
  connectTelegram: 'اتصال تلگرام',
  telegramDetails: 'جزییات حساب تلگرام شما',
  telegramAccount: 'حساب تلگرام',
  personalDetails: 'اطلاعات شخصی',
  personalDetailsInfo: 'جهت تغییر عکس پروفایل روی آن کلیک کنید.',
  userSettings: 'تنظیمات کاربری',
  userSettingsInfo:
    'شما در این قسمت میتوانید اطلاعات کاربری خود را مدیریت کنید.',
  active: 'فعال',
  deactivated: 'غیرفعال',
  emailAddress: 'آدرس ایمیل',
  sendSecurityKey: 'دریافت کد امنیتی',
  saveChanges: 'ذخیره تغییرات',
  save: 'ذخیره',
  cancel: 'انصراف',
  freeMembership: 'عضویت رایگان',
  vipMembership: 'اشتراک پریمیوم',
  vip: 'پریمیوم',
  profileUpdated: 'اطلاعات حساب کاربری بروزرسانی شد.',
  aliasIsDuplicated: 'نام مستعار تکراریست، لطفا نام دیگری انتخاب کنید!',
  emailIsDuplicated: 'آدرس ایمیل تکراریست، لطفا نام دیگری انتخاب کنید!',
  days: 'روز',

  "You're going to need a bigger monitor": 'Multi Chart!',
  'Your  plan has a 1 chart per tab limit. But our upgraded plans let you see up to 8 linked charts , perfectly synced , all in the same window.':
    'Your  plan has a 1 chart per tab limit. But our upgraded plans let you see up to 8 linked charts , perfectly synced , all in the same window.',
  "See what others don't with custom intervals":
    "See what others don't with custom intervals",
  "If you're doing analysis the same way as everyone else":
    "If you're doing analysis the same way as everyone else, how will you find your edge? With custom intervals spot the patterns most traders don't see — available only on our upgraded plans.",
  loginWithTelegram: 'ورود با تلگرام',
  login_description: 'please Scan This QRCode with your mobile phone...',
  goBack: 'بازگشت',
  loginWithEmail: 'ورود با ایمیل',

  login_2_description: () => (
    <>
      لطفا ربات تلگرام ما را استارت کنید (
      <a href="https://t.me/TradelyProBot" target="_blank" rel="noreferrer">
        @TradelyProBot
      </a>
      ) و از قسمت "لاگین در مرورگر" <strong> شناسه تلگرام</strong> و{' '}
      <strong> کد امنیتی </strong> جهت ورد به سایت دریافت کنید.
    </>
  ),
  emailLoginInfo: 'لطفا آدرس ایمیل خود را جهت دریافت کد امنیتی وارد نمایید.',
  emailLoginSecurityInfo:
    'لطفا کد امنیتی که به ایمیل شما ارسال شده است را وارد نمایید.',

  Filled: 'پایان یافته',
  Cancelled: 'کنسل شد',
  profit: 'سود: ',
  loss: 'ضرر: ',
  pips: 'پیپ',
  stopLoss: 'حد ضرر: ',
  takeProfit: 'حد سود: ',
  validTill: 'معتبر تا: ',
  // New
  oscillators: 'اوسیلاتورها',
  movingAverages: 'میانگین متحرک',
  summary: 'جمع بندی',
  pivots: 'پیوت',
  woodie: 'وودی',
  classic: 'کلاسیک',
  camarilla: 'کاماریلا',
  fibonacci: 'فیبوناچی',
  name: 'نام',
  value: 'مقدار',
  action: 'وضعیت',
  Buy: 'خرید',
  Sell: 'فروش',
  Neutral: 'خنثی',
  'Strong Sell': 'فروش شدید',
  'Strong Buy': 'خرید شدید',
  // New
  close: 'بستن',
  paymentInfo: (id) => (
    <>
      <Typography variant="h5">هزینه اشتراک:</Typography>
      <br />
      <Typography>
        ▪️ یک ماهه : $10
        <br />
        ▪️ سه ماهه: $27
        <br />
        ▪️ شش ماهه: $48
        <br />
        ▪️ یک ساله: $89
      </Typography>
      <Typography>
        لطفا هزینه اشتراک را به کیف پول زیر واریز کرده و جهت فعال سازی اشتراک با
        پشتیبانی تماس بگیرید :
        <a href="https://t.me/TradelySupport" target="_blank" rel="noreferrer">
          @TradelySupport
        </a>
        <br />
        در هنگام تماس با پشتیبانی شناسه اشتراک خود را ذکر کنید.
      </Typography>
      <Typography variant="h5">
        شناسه اشتراک شما:{' '}
        {id || (
          <small>جهت مشاهده شناسه اشتراک به حساب کاربریتان وارد شوید</small>
        )}
      </Typography>
    </>
  ),
  payUSDT: 'USDT',
  payUSDT2: 'پرداخت با USDT',
  payUSDTInfo: () => (
    <>
      <Typography variant="h5"> کیف پول USDT :</Typography>
      <br />
      <Typography variant="h5">TRC20 :</Typography>
      <Typography color="secondary">
        TGUzedJVa4waGVBwUvszHSYtUPwsqNoaD7
      </Typography>
      <Typography variant="h5">ERC20:</Typography>
      <Typography color="secondary">
        0x1e3b8a522f98fdd2aa79cdf48319abcbd912f79a
      </Typography>
      <Typography variant="h5">BSC:</Typography>
      <Typography color="secondary">
        0x1e3b8a522f98fdd2aa79cdf48319abcbd912f79a
      </Typography>
    </>
  ),
  payTCPay: 'تاپ چنج',
  payTCPay2: 'پرداخت با تاپ چنج',
  payTCPayInfo: () => (
    <>
      <Typography variant="h5">کیف پول تاپ چنج :</Typography>

      <Typography color="secondary">USD1033903</Typography>
    </>
  ),
  payWM: 'وب مانی',
  payWM2: 'پرداخت با وب مانی',
  payWMInfo: () => (
    <>
      <Typography variant="h5">کیف پول وب مانی :</Typography>

      <Typography color="secondary">Z175072480756</Typography>
    </>
  ),
  payPM: 'پرفکت مانی',

  payPM2: 'پرداخت با پرفکت مانی',
  payPMInfo: () => (
    <>
      <Typography variant="h5">کیف پول پرفکت مانی :</Typography>

      <Typography color="secondary">U19315856</Typography>
    </>
  ),
  freePremium: 'دریافت اشتراک رایگان',
  freePremiumInfo: () => (
    <>
      <Typography>
        در صورتی که در هر کدام از بروکر های زیر حساب فعال دارید جهت فعال کردن
        اشتراک رایگان با پشتیبانی تماس بگیرید.
        <br />
        <br />
        اگر در این بروکر ها حساب ندارید، از طریق لینک های زیر در یکی از بروکر ها
        اقدام به افتتاح حساب نموده و پس از شارژ حساب جهت فعال سازی اشتراک رایگان
        با پشتیبانی تماس بگیرید.
      </Typography>
      <br />
      <br />
      <Box textAlign="center">
        <Button
          href="https://amarketstrading.co/cashback-en/?g=7J5BN"
          target="_blank"
        >
          AMarkets
        </Button>
        <Button
          href="https://alparipartner.com/?partner_id=1260088"
          target="_blank"
        >
          Alpari
        </Button>
        <Button
          href="https://bz.myuserhub.com/?langSelectorCode=fa&fromLangSelector=dropdown&pt=40182"
          target="_blank"
        >
          Windsor Brokers
        </Button>
        <Button
          href="https://farsi.fibo-forex.org/?ref=IB_Tradely"
          target="_blank"
        >
          FiboGroup
        </Button>
        <Button href="https://my.roboforex.com/en/?a=csem" target="_blank">
          RoboForex
        </Button>
        <Button
          href="https://www.litefinance.com/fa/?uid=110655160"
          target="_blank"
        >
          Lite Forex(LiteFinance)
        </Button>
        <Button
          href="https://myaccount.opofinance.com/links/go/1073"
          target="_blank"
        >
          OpoFinance
        </Button>
      </Box>
      <br />
      <Box textAlign="center">
        <Button
          href="https://telegram.me/TradelySupport"
          target="_blank"
          variant="outlined"
          color="success"
        >
          تماس با پشتیبانی
        </Button>
      </Box>
    </>
  ),
  paymentMethods: 'یک روش پرداخت را انتخاب کنید:',
  closeChart: 'بستن نمودار',
  membersArea: 'ناحیه کاربری',
  dashboard: 'پیشخوان',
  contactSupport: 'تماس با پشتیبانی',
  premiumBenefits: () => (
    <List
      dense={false}
      subheader={
        <ListSubheader component="h1" sx={{ fontSize: 25 }}>
          مزایای اشتراک پریمیوم
        </ListSubheader>
      }
    >
      <ListItem divider>
        <ListItemText
          primary="سیگنال فارکس"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="دریافت سیگنال های فارکس بر روی 9 جفت ارز"
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="اعلان ها"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="به تعداد نامحدود اعلان ثبت کنید."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="چند نمودار!"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary=" نمایش چندین نمودار در یک پنجره بصورت همزمان تا ۸ نمودار."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="اندیکاتور ها"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="اضافه کردن اندیکاتور به تعداد نامحدود به نمودار."
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary="تایم فریم ها"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary="استفاده از تایم فریم های ویژه بر روی نمودار"
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="انواع نمودار"
          primaryTypographyProps={{
            sx: { fontSize: 18, fontWeight: 700 }
          }}
          secondary=" استفاده از نمودار های Renko, Kagi, Line Break, and Point & Figure  جهت کاهش نویز بازار و پیدا کردن بهتر روند."
        />
      </ListItem>
    </List>
  ),
  yourAccountStatus: 'وضعیت اشتراک شما',
  notPremium: 'اشتراک پریمیوم ندارید!',
  daysOfPremium: 'روز از اشتراکتان باقی مانده.',
  homeTitle: 'تریدلی - Tradely',
  homeKeywords:
    'tradely,تریدلی,رمز ارز,ارز دیجیتال,فارکس, سیگنال رایگان,تریدینگ ویو,متا تریدر',

  marketsKeywords:
    'market rates,cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  signalsDescription: 'سیگنال فارکس بر روی 9 جفت ارز با ضریب برد بالا',
  signalsKeywords:
    'EURUSD,GBPUSD,USDCHF,USDJPY,USDCAD,AUDUSD,EURJPY,NZDUSD,GBPCHF,سیگنال فارکس,متا تریدر,تکنیکال',

  premiumKeywords:
    'free premium,vip,cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  technicalKeywords:
    ',cryptocurrency,forex,trading,charts,technical analysis,forex signals, crypto signals,tradingview,metatrader',

  chartTitle: (symbol) => `نمودار قیمت ${symbol} -تریدلی`,
  chartInfo: (symbol) => `نمودار قیمت پیشرفته ${symbol} - تریدلی`,
  chartKeyword: (symbol) =>
    `${symbol}, Price Chart,tradingview,metatrader,نمودار قیمت,تریدینگ ویو,متا تریدر`,
  telegramLink: 'https://t.me/TradelyFa',
  instagramLink: 'https://instagram.com/tradely.fa',
  youtubeLink: 'https://www.youtube.com/channel/UC_hL2MdtkYh-e2f4oAXaxAg',
  thisWeek: 'این هفته',
  lastWeek: 'هفته پیش',
  thisMonth: 'این ماه',
  lastMonth: 'ماه پیش',
  signalsResult: 'نتایج سیگنال ها',
  yourIdea: 'دیدگاه های شما',
  publishIdea: 'انتشار دیدگاه',
  description: 'توضیحات'
};

// export default function fa() {
//   return faConst;
// }
