export const AdminConstants = {
  GET_ALL_USERS: 'GET_ALL_USERS',

  GET_ALL_BLOGS: 'GET_ALL_BLOGS',

  GET_ALL_PAGES: 'GET_ALL_PAGES',

  GET_ALL_PAYMENTS: 'GET_ALL_PAYMENTS',

  GET_ALL_EVENTS: 'GET_ALL_EVENTS',
  GET_ALL_SYMBOLS: 'GET_ALL_SYMBOLS'
};
