import { snackbarConstants } from '../constants/snackbar-constants';

const defaultState = {
  notifications: []
};

export function snackbarReducers(state = [], action) {
  switch (action.type) {
    case snackbarConstants.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    case snackbarConstants.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case snackbarConstants.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        )
      };

    default:
      return state;
  }
}
