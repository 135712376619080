import { AdminConstants } from '../constants/admin-constants';

// eslint-disable-next-line default-param-last
export function AdminReducers(state = [], action) {
  switch (action.type) {
    case AdminConstants.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };

    case AdminConstants.GET_ALL_BLOGS:
      return {
        ...state,
        blogs: action.payload
      };

    case AdminConstants.GET_ALL_PAYMENTS:
      return {
        ...state,
        payments: action.payload
      };

    case AdminConstants.GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload
      };

    case AdminConstants.GET_ALL_PAGES:
      return {
        ...state,
        pages: action.payload
      };
    case AdminConstants.GET_ALL_SYMBOLS:
      return {
        ...state,
        symbols: action.payload
      };

    default:
      return state;
  }
}
