// eslint-disable-next-line import/no-cycle
import AppRouter from './routers/app-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import configureStore from './redux/store/configureStore';
import ThemeProvider from './theme/ThemeProvider';
import { Collapse, CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import Notifier from './components/snackbar-notifier';
import { SnackbarProvider } from 'notistack';

export const store = configureStore;

const App = () => {
  // const content = useRoutes(routes);

  return (
    <Provider store={store}>
      {/* <GlobalProvider> */}
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />

          <SnackbarProvider
            dense={false}
            preventDuplicate
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            TransitionComponent={Collapse}
            hideIconVariant
          >
            <Notifier />

            <AppRouter />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
      {/* </GlobalProvider> */}
    </Provider>
  );
};
export default App;
