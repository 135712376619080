import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
// import SidebarLayout from '../theme/layouts/SidebarLayout';
// import BaseLayout from '../theme/layouts/BaseLayout';

import SuspenseLoader from '../components/SuspenseLoader';
import { PrivateRoute } from './private-routes';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const BaseLayout = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../theme/layouts/BaseLayout'))
);
const MiniAppLayout = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../theme/layouts/MiniAppLayout'))
);

const SidebarLayout = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../theme/layouts/SidebarLayout'))
);
// Pages

// const Overview = Loader(lazy(() => import("../pages/content/overview")));

// Dashboards

// const Crypto = Loader(lazy(() => import('../pages/content/dashboards/Crypto')));

// Applications

// const Messenger = Loader(
//   lazy(() => import("../pages/content/applications/Messenger"))
// );
// const Transactions = Loader(
//   lazy(() => import("../pages/content/applications/Transactions"))
// );
// const UserProfile = Loader(
//   lazy(() => import("../pages/content/applications/Users/profile"))
// );
// const UserSettings = Loader(
//   lazy(() => import("../pages/content/applications/Users/settings"))
// );
//
// // Components
//
// const Buttons = Loader(
//   lazy(() => import("../pages/content/pages/Components/Buttons"))
// );
// const Modals = Loader(
//   lazy(() => import("../pages/content/pages/Components/Modals"))
// );
// const Accordions = Loader(
//   lazy(() => import("../pages/content/pages/Components/Accordions"))
// );
// const Tabs = Loader(
//   lazy(() => import("../pages/content/pages/Components/Tabs"))
// );
// const Badges = Loader(
//   lazy(() => import("../pages/content/pages/Components/Badges"))
// );
// const Tooltips = Loader(
//   lazy(() => import("../pages/content/pages/Components/Tooltips"))
// );
// const Avatars = Loader(
//   lazy(() => import("../pages/content/pages/Components/Avatars"))
// );
// const Cards = Loader(
//   lazy(() => import("../pages/content/pages/Components/Cards"))
// );
// const Forms = Loader(
//   lazy(() => import("../pages/content/pages/Components/Forms"))
// );

// Status

// const Status404 = Loader(
//   lazy(() => import("../pages/content/pages/Status/Status404"))
// );
// const Status500 = Loader(
//   lazy(() => import("../pages/content/pages/Status/Status500"))
// );
// const StatusComingSoon = Loader(
//   lazy(() => import("../pages/content/pages/Status/ComingSoon"))
// );
// const StatusMaintenance = Loader(
//   lazy(() => import("../pages/content/pages/Status/Maintenance"))
// );

const MiniApp = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/miniApp/index'))
);
const Login2 = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/login-page2'))
);
const TelegramLogin = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/telegram-login'))
);

const EmailLogin = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/email-login-page'))
);
// const BlogListingPage = Loader(lazy(() => import('../pages/client/main/blog-listing-page')));
// eslint-disable-next-line no-unused-vars
const BlogPostPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/blog-post-page'))
);
// eslint-disable-next-line no-unused-vars
const PremiumPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/premium-page'))
);
// eslint-disable-next-line no-unused-vars
const AssistantPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/assistant-page'))
);
// const ExtraPage = Loader(lazy(() => import('../pages/client/main/extra-page')));
// eslint-disable-next-line import/no-cycle
const ChartPage = Loader(lazy(() => import('../pages/client/main/chart')));
// const AnalysisPage = Loader(lazy(() => import('../pages/client/main/analysis')));
// const UserProfilePage = Loader(lazy(() => import('../pages/client/main/user-profile')));
//
// eslint-disable-next-line no-unused-vars
const ProfilePage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/profile'))
);
// eslint-disable-next-line no-unused-vars
const SettingsPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/settings'))
);

// eslint-disable-next-line no-unused-vars
const EditProfilePage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/edit-profile-page'))
);
// const WalletPage = Loader(lazy(() => import('../pages/client/dashboard/wallet')));
// const RevenuePage = Loader(lazy(() => import('../pages/client/dashboard/revenue')));
// const PaymentCallbackPage = Loader(lazy(() => import('../pages/client/dashboard/payment-callback-page')));
// const FollowersPage = Loader(lazy(() => import('../pages/client/dashboard/followers')));
// const FollowingsPage = Loader(lazy(() => import('../pages/client/dashboard/followers')));

const UsersListPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/admin/users-list-page'))
);
// const PaymentsListPage = Loader(lazy(() => import('../pages/admin/payments-list-page')));
const BlogListPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/admin/blog-list-page'))
);
// eslint-disable-next-line no-unused-vars
const TechnicalPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/technical-page'))
);
// const PagesListPage = Loader(lazy(() => import('../pages/admin/pages-list-page')));

const SymbolsListPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/admin/symbols-list-page'))
);

const EventsListPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/admin/events-list-page'))
);
// eslint-disable-next-line no-unused-vars
const DashboardPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/dashboard/dashboard'))
);
// eslint-disable-next-line no-unused-vars
const MarketsPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/Markets'))
);

// eslint-disable-next-line no-unused-vars
const CryptoMarketPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/crypto-market'))
);
// eslint-disable-next-line no-unused-vars
const ForexSignals = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/main/forex-signals'))
);

const mlRoutes = () => {
  return (
    <Route>
      {/* <Route index element={<SidebarLayout />} /> */}
      {/* <Route path="" element={<SidebarLayout />}> */}
      <Route element={<BaseLayout />}>
        <Route path="login" element={<Login2 />} />
        <Route path="tauth" element={<TelegramLogin />} />
        <Route path="email-login" element={<EmailLogin />} />
        <Route path="chart/:symbol/:interval" element={<ChartPage />} />
      </Route>

      <Route element={<MiniAppLayout />}>
        <Route path="miniapp" element={<MiniApp />} />
      </Route>

      <Route element={<SidebarLayout />}>
        <Route path="symbols/:symbol" element={<TechnicalPage />} />
        <Route path="forex-signals" element={<ForexSignals />} />
        <Route path="blog/:slug" element={<BlogPostPage />} />
        <Route path="forex-market" element={<MarketsPage market="Forex" />} />
        <Route path="premium" element={<PremiumPage />} />
        <Route path="assistant" element={<AssistantPage />} />
        <Route
          path="cryptocurrency-market"
          element={<CryptoMarketPage market="Crypto" />}
        />
        <Route index element={<DashboardPage />} />
        <Route path="dashboard">
          <Route
            path="edit-profile"
            element={<PrivateRoute element={<EditProfilePage />} />}
          />
          <Route
            path="profile"
            element={<PrivateRoute element={<ProfilePage />} />}
          />
          <Route
            path="settings"
            element={<PrivateRoute element={<SettingsPage />} />}
          />
        </Route>

        <Route path="acp">
          <Route
            path="acp"
            element={
              <PrivateRoute
                element={<Navigate to="/acp/dashboard" replace />}
              />
            }
          />
          <Route
            path="users-list"
            element={<PrivateRoute element={<UsersListPage />} />}
          />

          <Route
            path="symbols-list"
            element={<PrivateRoute element={<SymbolsListPage />} />}
          />
          <Route
            path="events-list"
            element={<PrivateRoute element={<EventsListPage />} />}
          />
          <Route
            path="blog-list"
            element={<PrivateRoute element={<BlogListPage />} />}
          />
        </Route>
      </Route>
      {/* </Route> */}
    </Route>
  );
};

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {mlRoutes()}
            <Route path=":locale/">
              {mlRoutes()}

              {/* <Route path="*" component={NotFound} /> */}
            </Route>
          </Route>

          {/* <Route path="/" element={<Navigate to="/en" replace />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
}

// };

export default AppRouter;
