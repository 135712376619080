// import { createStore, applyMiddleware, compose } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from '@redux-devtools/extension';
import { rootReducer } from '../reducers';
import { configureStore } from '@reduxjs/toolkit';

// import todosReducer from './features/todos/todosSlice'
// import filtersReducer from './features/filters/filtersSlice'

// const store = configureStore({
//   reducer: rootReducer
// });

const store = configureStore({ reducer: rootReducer });
// export default function AdminReducers(state = [], action) {
export default store;
// export  configureStore({
//   reducer: rootReducer
// });

// const store = configureStore({ reducer: rootReducer });
// export default store;
// const middleware = [thunkMiddleware];

// const configureStore = () => {
//   return createStore(rootReducer, compose(applyMiddleware(...middleware)));
// };

// export default configureStore({
//   reducer: rootReducer,
//   middleware: () => new Tuple(middleware)
// });
//
// import { applyMiddleware, createStore } from 'redux';
// import { composeWithDevTools } from '@redux-devtools/extension';
// import { createLogger } from 'redux-logger';
// import thunkMiddleware from 'redux-thunk';
// import rootReducer from '../reducers';
//
// const middleware = [thunkMiddleware];
// const logger = createLogger({
//   // ...options
// });
// const configureStore = () => {
//   return createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(...middleware, logger))
//   );
// };
// export default configureStore;
