// import { combineReducers } from 'redux';

import { GlobalReducers } from './global-reducers';
import { ClientReducers } from './client-reducers';
import { AdminReducers } from './admin-reducers';
import { snackbarReducers } from './snackbar-reducers';

// const appReducer = combineReducers({
//   GlobalReducers,
//   ClientReducers,
//   snackbarReducers,
//   AdminReducers
// });
export const rootReducer = {
  GlobalReducers,
  ClientReducers,
  snackbarReducers,
  AdminReducers
};

// const rootReducer = (state, action) => {
//   return appReducer;
// };
//
// export default rootReducer;
