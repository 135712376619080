// import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import 'nprogress/nprogress.css';
// import './theme/layouts/css/ck.css';
import { SidebarProvider } from './providers/SidebarContext';
import { getLS, setLS } from './utils/storage';
import { createRoot } from 'react-dom/client';
console.log('TEST1');
if (!getLS('lang')) {
  setLS('lang', 'en');
}
// ReactDOM.render(
//   <HelmetProvider>
//     <SidebarProvider>
//       {/* <BrowserRouter> */}
//       <App />
//       {/* </BrowserRouter> */}
//     </SidebarProvider>
//   </HelmetProvider>,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <HelmetProvider>
    <SidebarProvider>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
    </SidebarProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorker.unregister();
